<script>
import interact from "interactjs";
export default {
  inject: ["app"],
  data: function() {
    var self = this;
    return {
      interactPosition: {
        x: 40 * self.app.windows.length,
        y: 30 * self.app.windows.length
      },
      title: ""
    };
  },
  watch: {
    /*minimized: function(val) {
      if (val) {
        this.$el.classList.add("window-minimized");
      } else {
        this.$el.classList.remove("window-minimized");
      }
    },*/
    title: function(val) {
      this.$set(this.win, "title", val);
    }
  },
  methods: {
    debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this,
          args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    onMousedown: function(e) {
      var self = this;
      if (self.minimized) return;
      if (window.$(e.path).filter(".button-close").length) return;
      self.$emit("focus");
    },
    interactSetPosition(coordinates) {
      const { x = 0, y = 0 } = coordinates;
      this.interactPosition = { x, y };
      this.setTransform();
    },
    setTransform() {
      const { x, y } = this.interactPosition;
      this.$el.style.transform = `translate3D(${x}px, ${y}px, 0)`;
    }
  },
  props: {
    win: { type: Object },
    winParent: { type: Object },
    winParentList: { type: Array },
    minimized: { type: Boolean },
    winOptions: {
      type: Object,
      default: function() {
        return {};
      }
    },
    resizable: { type: Boolean, default: false }
  },
  created: function() {},
  mounted: function() {
    this.$set(this.win, "instance", this);
    this.$set(this.win, "title", this.title);
    this.$el.onmousedown = this.debounce(this.onMousedown, 0);
    let int = interact(this.$el).draggable({
      allowFrom: ".window:not(.window-minimized)>header",
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent"
        })
      ],
      onmove: event => {
        const x = this.interactPosition.x + event.dx;
        const y = this.interactPosition.y + event.dy;
        this.interactSetPosition({ x, y });
      }
    });
    if (this.resizable)
      int.resizable({
        edges: { left: true, right: true, bottom: true, top: false },
        //inertia: true,
        modifiers: [
          interact.modifiers.restrictEdges({
            outer: "parent"
          }),
          interact.modifiers.aspectRatio({
            ratio: "preserve"
          }),
          interact.modifiers.restrictSize({
            min: { width: 100, height: 132 }
          })
        ],
        listeners: {
          move: event => {
            var target = event.target;
            target.style.width = event.rect.width + "px";
            target.style.height = event.rect.height + "px";

            const x = this.interactPosition.x + event.deltaRect.left;
            const y = this.interactPosition.y + event.deltaRect.top;
            this.interactSetPosition({ x, y });
          }
        }
      });

    this.setTransform();
  },
  beforeDestroy() {
    interact(this.$el).unset();
  }
};
</script>
<style>
.window {
  background: white;
  width: max-content;
  box-shadow: 0px 5px 5px #aaa;
  border-radius: 3px;
  /*overflow: hidden;*/
  overflow: visible;
  touch-action: none;
  position: absolute;
  z-index: 999;
  border: 1px solid #aaa;
}
.window header {
  background: #ddd;
  height: 32px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.window-active header {
  background: linear-gradient(
    to right,
    rgba(0, 140, 186, 0.3) 0%,
    rgba(0, 140, 186, 0.5) 60%,
    rgba(0, 140, 186, 0.8) 100%
  );
}

.window header .right {
  margin-left: auto;
}
.window nav {
  padding: 8px 10px;
  border-bottom: 1px solid #ccc;
  background: #f7f7f8;
  display: flex;
}

/*.window-minimized {
  transform: none !important;
  position: relative;
}*/
.window-minimized {
  display: none;
}

.button-close,
.button-minimize {
  padding: 0;
  height: 1.7em;
  width: 1.7em;
}

</style>